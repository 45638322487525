import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * deals with all the requests that have to do with accounts
 */
export default class AccountDeletionRepository {
    /**
     * gets the export data
     */
    public static deleteAccount(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`api/account/delete/request`);
    }

    public static deleteAccountConfirm(token: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.delete(`api/account/delete/${token}`);
    }
}
