
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { namespace } from "vuex-class";
import { AuthStoreActions, AUTH_STORE_NAME } from "@/store/auth.store";
import {ROUTE_HOME, ROUTE_LOGIN} from "@/router/routes";
import {StoreActions} from "@/store";
import AccountDeletionRepository from "@/api/repositories/AccountDeletionRepository";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {
    LogoComponent: () => import(
        /* webpackChunkName: "LogoComponent" */
        '@/components/Logo.component.vue'
        ),
  },
})
export default class DeleteAccountConfirmView extends mixins(ErrorMessageHandlerMixin) {
  /**
   * Reset Token fetched by the URL Query
   * @private
   */
  private token?: string;

  /**
   * Alert Message to Show
   * @private
   */
  private alertMessage: string = "";

  /**
   * State bool to show the Alert above the Form
   * @private
   */
  private showAlert: boolean = false;

  /**
   * Loading State Bool
   * @private
   */
  private isLoading: boolean = false;

  /**
   * Form Locked State Bool
   * @private
   */
  private isLocked: boolean = false;

  /**
   * Is Mobile Vuetify Breakpoint Triggered
   */
  public get isMobile() {
    return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
  }

  /**
   * Created Hook
   * Fetches the Reset Token from the URL Query
   */
  async created() {
    // Reset all stores:
    await this.$store.dispatch(StoreActions.CLEAR_ALL_STORES);

    // try to get token from query param
    const token = this.$route.query.token;

    // Token is not available through a query param
    // show an error describing that issue
    if (!token) {
      this.isLocked = true;
      this.alertMessage = this.$t("PASSWORD.INVALID_RESET_TOKEN").toString();
      this.showAlert = true;
      return;
    }

    // Parse given token as String
    this.token = token as string;
  }

  /**
   * Reset Password Method
   * This will Reset the Password via an API call
   * @private
   */
  private async deleteAccount() {
    // Disable Alert Message
    this.showAlert = false;

    // Try to set the new Password
    try {
      // set Loading to be active
      this.isLoading = true;
      await AccountDeletionRepository.deleteAccountConfirm(this.token!);

      // Show a success message
      await this.$notifySuccessSimplified("GENERAL.NOTIFICATIONS.DELETE_DATA_SUCCESS");

      await this.onLogout();
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          // No Account or Token Found
          case 404:
            this.alertMessage = this.$t("DELETE_ACCOUNT.TOKEN_NOT_FOUND").toString();
            break;
          case 422:
            this.alertMessage = this.$t("DELETE_ACCOUNT.CANNOT_DELETE_ACCOUNT").toString();
            break;
          case 499:
            this.alertMessage = this.$t("DELETE_ACCOUNT.EXPIRED_TOKEN").toString();
            break;
          default:
            this.alertMessage = this.$t("GENERAL.NOTIFICATIONS.GENERAL_ERROR.TEXT").toString();
        }

        // Show Alert Message
        this.showAlert = true;
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * On Logout handler
   */
  private async onLogout() {
    // Reset all stores:
    await this.$store.dispatch(StoreActions.CLEAR_ALL_STORES);

    // redirects to login view
    if(this.$route.name !== ROUTE_LOGIN) await this.$router.push({name: ROUTE_LOGIN});
  }
}
